body, html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.anchor {
  scroll-behavior: smooth;
  scroll-margin-top: 80px;
}
h1, h2, h3, h4, h5, p, input, textarea, a, li {
  font-family: 'Montserrat', sans-serif;
}
a {
  text-decoration: none;
}
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  position: fixed;
  background: #FFF;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.logo {
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: #107AB0;
}
.logo span {
  color: #555;
}
.logo p {
  margin: 0;
}
.logo img {
  height: 80px;
}
.navigation {
  padding-right: 20px;
}
.footer {
  background: #000;
  padding: 80px;
  color: #FFF;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.footer img {
  height: 150px;
}
.footer > div {
  flex: 1;
}
.footer a {
  color: #FFF;
  transition: all 0.3s ease;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer a:hover {
  color: #107AB0;
  transition: all 0.3s ease;
}
.footer p {
  margin: 0;
  font-size: 14px;
  line-height: 1.8;
}
.footer h4 {
  margin: 0;
  margin-bottom: 20px;
}
.footer svg {
  fill: #FFF;
  height: 15px;
  transition: all 0.3s ease;
}
.footer .iconWrap {
  height: 15px;
  margin-right: 10px;
}
.footer-bottom {
  background: #000;
  color: #FFF;
  font-size: 12px;
  text-align: center;
  margin: auto;
  border-top: 1px solid grey;
  padding: 20px 0;
}
.footer-bottom p {
  margin: 0;
}
.footer-bottom svg {
  height: 15px;
  width: 15px;
  fill: #FFF;
  margin: auto;
}
.footer a:hover svg {
  fill: #107AB0;
  transition: all 0.3s ease;
}
.footer-bottom .social-icons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
}
.footer-bottom span {
  background: #107AB0;
  border-radius: 30px;
  display: flex;
  height: 25px;
  width: 25px;
  transition: all 0.3s ease;
}
.footer-bottom span:hover {
  background: #084461;
  transition: all 0.3s ease;
}
.header a {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  color: initial;
  font-size: 17px;
  padding: 0 20px;
  transition: all .3s ease;
  text-decoration: none;
}
.header a:hover {
  color: #107AB0;
  transition: all .3s ease;
  transform: scale(1.2);
}
a.apply-button {
  background: #107AB0;
  color: #FFF;
  border-radius: 50px;
  padding: 10px 20px;
  transition: all .3s ease;
  cursor: pointer;
}
.header a.apply-button {
  margin-left: 20px;
}
a.apply-button:hover {
  color: #FFF;
  background-color: #084461;
}
a.contact-button {
  background: #FFF;
  color: #000;
  border-radius: 50px;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  transition: all .3s ease;
}
.hero a {
  padding: 12px 35px;
  font-size: 18px;
}
a.contact-button:hover {
  transition: all .3s ease;
  color: #FFF;
  background-color: #107AB0;
}
.hero {
  background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5) ), url('/public/LandingPage.png');
  background-size: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #FFF;
  padding: 180px 30px 100px 30px;
  line-height: 1.2;
}
.hero h2 {
  margin: 0;
  font-size: 24px;
  margin-bottom: 40px;
}
.hero h1 {
  margin: 0;
  font-size: 50px;
  margin-bottom: 40px;
}
.hero h3 {
  font-size: 32px;
  margin: 0;
  margin-bottom: 32px;
}
.about-us, .choose-us-inner, .services-inner, .contact-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  max-width: 1000px;
  margin: auto;
}
.services-inner {
  padding-bottom: 45px;
}
.choose-us-inner {
  padding-bottom: 55px;
}
.services-inner, .contact-inner {
  max-width: 1200px;
}
.contact-page {
  padding-top: 60px;
}
span.heading-wrap-left, span.heading-wrap-right {
  display: flex;
  width: 50%;
  max-width: 1000px;
  align-items: center;
}
span.heading-wrap-left.heading-wrap-right {
  width: 35%;
  margin: auto;
}
.about-us h5, .choose-us h5, .services h5 {
  color: #107AB0;
  font-size: 16px;
  margin: 0 10px;
}
.heading-wrap-left:before {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2px solid #107AB0;
}
.heading-wrap-right:after {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2px solid #107AB0;
}
.heading-wrap-left-white:before, .heading-wrap-right-white:after {
  border-block-start: 2px solid #FFF;
}
.about-us > div {
  flex: 1 1;
}
.choose-us-inner > div {
  flex: 1 1;
}
.about-us img, .choose-us-inner .choose-img-wrap img {
  width: 100%;
}
.about-us h1, .choose-us h1, .services h1 {
  font-size: 40px;
}
.about-us p {
  font-size: 18px;
}
.about-img-wrap {
  margin-right: 25px;
}
.choose-us, .services {
  background: #DDEDF6;
}
.choose-us-inner .choose-img-wrap {
  margin-left: 25px;
}
.choose-us-inner span.choose-icon {
  background: #107AB0;
  border-radius: 5px;
  height: 60px;
  display: inline-block;
  padding: 5px;
  margin-right: 15px;
}
.choose-us-inner span img {
  height: 60px;
  margin-left: 0;
}
.choose-us p {
  margin: 0;
}
.choose-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.choose-text {
  align-self: flex-start;
}
.funding-journey {
  background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5) ), url('/public/Funding-Journey.jpg');
  background-size: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #FFF;
  text-align: center;
  padding: 80px 30px 80px 30px;

}
.funding-journey h5 {
  margin: 0 10px;
}
.funding-journey p {
  line-height: 30px;
}
.funding-boxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-flow: row;
  column-gap: 20px;
  align-items: center;
}
.funding-boxes > div {
  flex: 1;
}
.funding-boxes > div.funding-arrow {
  flex: 0;
}
.funding-arrow svg {
  height: 80px;
  opacity: 0.8;
  fill: #999;
}
.funding-box {
  height: 250px;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 10px;
}
.funding-box > div {
  height: 250px;
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 15px;
}
.funding-box:hover > div {
  transform: translate3d(0px, -250px, 0px);
  transition: transform 0.6s ease-in-out;
}
.funding-box > div + div {
  background: rgba(25, 33, 104, 0.5);
  
}
.funding-box h4 {
  margin: 0;
  font-size: 18px;
  line-height: 18px;
}
.funding-box p {
  margin: 0;
  margin-top: -80px;
  font-size: 14px;
  line-height: 15px;
}
.services-inner {
  text-align: center;
}
.services-inner > div {
  width: 100%;
}
.service-boxes {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-around;
}
.service-boxes a {
  color: #107AB0;
  align-self: self-end;
}
.service-boxes > div {
  flex-basis: calc(33.33333% - 35px);
  flex-grow: 0;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.service-boxes img {
  max-width: 100%;
  height: 200px;
}
.service-box-img {
  height: 200px;
  text-align: center;
  background: #084461;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
}
.service-box-text {
  padding: 20px;
  flex: 1;
  display: grid;
}
.service-boxes h4 {
  margin: 8px 0;
  color: #107AB0;
}
.service-boxes p {
  margin: 8px 0;
  line-height: 1.5;
}

.contact-left {
  background: #107AB0;
  padding: 30px;
  color: #FFF;
  flex-basis: 40%;
  border-radius: 15px 0 0 15px;
}
.contact-inner a {
  background-color: #FFF;
  fill: #107AB0;
  color: #107AB0;
  display: block;
  width: 40px;
  height: 40px;
  padding: 11px;
  text-align: center;
  border-radius: 40px;
  margin-right: 15px;
  transition: all 0.3s ease;
}
.contact-inner a:hover {
  background: #084461;
  fill: #FFF;
  color: #FFF;
  transition: all 0.3s ease;
}
.contact-inner .socials a {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 25px;
}

.contact-inner svg {
  width: 30px;
  height: 40px;
}

.contact-inner h1 {
  margin: 10px 0;
}

.contact-inner .socials svg {
  width: 15px;
  height: 25px;
}
.contact-right {
  flex-basis: 60%;
  padding: 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 0 15px 15px 0;
}
.contact-inner > div {
  display: flex;
}
.contact-left-row, .contact-left .socials {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.contact-left span h5 {
  font-size: 18px;
}
.contact-left span h5, .contact-left span p {
  margin: 5px 0;
}
.contact-left h4 {
  font-size: 20px;
}
.flex-break {
  flex-basis: 100%;
  height: 0;
}
#email_form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}
#email_form input[type=text], #email_form textarea {
  padding: 6px 16px;
  background: #DDEDF6;
  border-width: 0;
  border-radius: 4px;
  height: 35px;
  font-size: 16px;
  width: 100%;
  flex: 1;
  margin-bottom: -20px;
}
#email_form textarea {
  height: 100px;
  resize: vertical;
}
#email_form input[type=text]::placeholder, #email_form textarea::placeholder {
  font-size: 16px;
}
#email_form input[type=text]:focus-visible, #email_form textarea:focus-visible {
  outline: 1px solid #107AB0;
}
#email_form input[type=submit] {
  background: #107AB0;
  border-width: 0;
  border-radius: 30px;
  color: #FFF;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 30px;
  min-width: 100px;
  transition: all 0.3s ease;
}
#email_form input[type=submit]:hover {
  background: #084461;
  transition: all 0.3s ease;
}
#email_form input[type=submit]:disabled {
  background: #728C67;
  cursor: default;
}
.service-page {
  padding: 100px;
}
.service-page h1 {
  text-align: center;
  font-size: 50px;
  color: #107AB0;
}
@media screen and (max-width: 922px) {
  .anchor {
    scroll-margin-top: 0px;
  }
  .header {
    height: 60px;
    position: relative;
  }
  .logo img {
    height: 60px;
  }
  .header a.apply-button {
    margin-left: 10px;
    padding: 5px 10px;
  }
  .hero {
    line-height: 1.5;
    padding: 60px 30px 40px 30px;
  }
  .hero h2 {
    font-size: 18px;
    font-weight: 400;
  }
  .hero h1 {
    font-size: 30px;
  }
  .hero h3 {
    font-size: 16px;
    font-weight: 400;
  }
  .about-us h1, .choose-us-inner h1, .services-inner h1, .contact-inner h1, .funding-journey h1 {
    font-size: 30px;
  }
  .about-us p, .choose-us-inner p, .services-inner p, .contact-inner p, .funding-journey p {
    font-size: 16px;
  }
  .about-us, .choose-us-inner, .services-inner, .contact-inner, .funding-journey {
    padding: 40px 20px;
  }
  .contact-page {
    padding-top: 0px;
  }
  .funding-box p {
    font-size: 12px;
    line-height: 15px;
  }
  .funding-box h4 {
    line-height: 20px;
    font-size: 14px;
    line-height: 16px;
  }
  .funding-arrow svg {
    height: 45px;
  }
  .footer img {
    height: 100px;
  }
  .footer a, .footer p {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    display: block;
    margin: auto;
    height: initial;
    padding-bottom: 10px;
    text-align: center;
  }
  .logo {
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
  .logo img {
    height: 40px;
  }
  .navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .header a.apply-button {
    margin-left: 0px;
    padding: 2px 10px;
  }
  .header a {
    padding: 0;
    font-size: 14px;
  }
  .header a:hover {
    transform: none;
  }
  .navigation {
    padding-right: 0;
    margin: 0 10px;
  }
  .hero a {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 6px 0;
  }
  a.contact-button {
    margin-left: 0;
  }
  .about-us, .choose-us-inner, .services-inner, .contact-inner {
    flex-direction: column;
    padding: 20px;
  }
  .choose-us-inner {
    flex-direction: column-reverse;
  }
  .about-us img {
    margin-right: 0;
    margin-bottom: 30px;
  }
  span.heading-wrap-left, span.heading-wrap-right {
    width: 80%;
  }
  span.heading-wrap-left.heading-wrap-right {
    width: 80%;
  }
  .about-us h1, .choose-us-inner h1, .services-inner h1, .contact-inner h1, .funding-journey h1 {
    font-size: 24px;
  }
  .about-us p, .choose-us-inner p, .services-inner p, .contact-inner p, .funding-journey p {
    font-size: 14px;
  }
  .choose-us-inner > div {
    width: 100%;
  }
  .choose-us-inner > div + div {
    text-align: center;
  }
  .choose-us-inner .choose-img-wrap {
    margin: 0 0 20px;
  }
  .funding-boxes {
    display: block;
  }
  .funding-boxes > div {
    margin-bottom: 20px;
  }
  .funding-journey {
    padding: 40px 20px 20px;
  }
  .funding-boxes p {
    line-height: 24px;
  }
  .funding-boxes h4 {
    font-size: 20px;
  }
  .funding-arrow svg {
    transform: rotate(90deg);
  }
  .service-boxes {
    display: block;
  }
  .service-boxes > div {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  .services-inner {
    padding-top: 40px;
  }
  .contact-inner > div {
    flex-direction: column;
  }
  .contact-left {
    border-radius: 15px 15px 0 0;
    padding: 20px;
  }
  .contact-left-row, .contact-left .socials {
    margin-bottom: 15px;
  }
  .contact-right {
    border-radius: 0 0 15px 15px;
    padding: 20px;
  }
  .contact-inner h1 {
    margin: 0;
    font-size: 20px;
  }
  .contact-inner p {
    margin: 10px 0;
  }
  .contact-inner h4 {
    margin: 0 0 10px 0;
  }
  #email_form input[type=submit] {
    width: 100%;
  }
  .footer {
    display: block;
    padding: 40px 30px 30px;
  }
  .footer img {
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  .footer h4 {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .about-img-wrap {
    margin-right: 0px;
  }
}


.fade-in-section {
  opacity: 0;
  transform: translateX(-20vh);
  visibility: hidden;
  transition: opacity 1.0s ease-out, transform 1.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-slow {
  opacity: 0;
  transform: translateX(-40vh);
  visibility: hidden;
  transition: opacity 1.5s ease-out, transform 2.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-right {
  opacity: 0;
  transform: translateX(20vh);
  visibility: hidden;
  transition: opacity 1.0s ease-out, transform 1.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-top {
  opacity: 0;
  transform: translateY(-10vh);
  visibility: hidden;
  transition: opacity 1.0s ease-out, transform 1.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-bottom {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 1.0s ease-out, transform 1.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible, .fade-in-section-slow.is-visible, .fade-in-section-right.is-visible,
.fade-in-section-top.is-visible, .fade-in-section-bottom.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}